.card {
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 1px 0px rgba(0, 0, 0, 0.07), 0 1px 3px 0px rgba(0, 0, 0, 0.06);
}

.card:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.statusfont{
    font-weight: bold;
    color: #888;
    /* 添加其他样式效果 */
    line-height: 1.5; /* 行高为1.5倍，使文本更易读 */
    text-align: left; /* 文本居中对齐 */
    text-transform: uppercase; /* 转换文本为大写 */
    padding: 10px; /* 添加内边距 */
    border: 1px solid #ccc; /* 添加边框 */
    border-radius: 2px; /* 添加圆角边框 */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.01); /* 添加阴影效果 */
}
.statusfont_focus{
    font-weight: bold;
    color: #888;
    /* 添加其他样式效果 */
    line-height: 1.5; /* 行高为1.5倍，使文本更易读 */
    text-align: left; /* 文本居中对齐 */
    text-transform: uppercase; /* 转换文本为大写 */
    padding: 10px; /* 添加内边距 */
    border: 1px solid #f65858; /* 添加边框 */
    border-radius: 2px; /* 添加圆角边框 */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.01); /* 添加阴影效果 */
}

.toppadding {
    margin-top: 20px; /* 设置顶部外边距 */
    margin-bottom: 5px; /* 设置底部外边距，增加与下一个背景的距离 */
    height: 20px; /* 高度 */
  }